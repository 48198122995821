<template>
  <v-main class="bg-white">
    <v-container class="py-0">
      <div class="main">
        <div>
          <img
            class="col-12 col-sm-9 col-md-6 col-xl-4"
            src="/img/maintenance.svg"
            alt="maintenance-img"
          />
        </div>
        <div class="main-text">
          <div class="text1 text text-h4 text-sm-h4 text-md-h3">
            We've got something special in platform for you.
          </div>
          <div class="text2 text text-h5 text-sm-h5 text-md-h4 pt-6">
            <div>And we can't wait for you to see it.</div>
            <div>Please check back soon.</div>
          </div>
          <div class="pt-8">
            <router-link to="/">
              <v-btn class="text-h5 text-sm-h5 text-md-6 text home-btn" outlined
                >Go to Main page</v-btn
              >
            </router-link>
          </div>
        </div>
      </div>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "Maintenance",
};
</script>

<style scoped>
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 100px 70px;
  height: 100vh;
  width: 100%;
}
.main-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.text1 {
  font-size: 3rem;
}
.text2 {
  font-size: 2rem;
}
.text {
  color: #4f52ff;
  font-weight: bold;
}
</style>
